<template>
<div class="mainform">
  <div class="mainHeader">
      {{formData.itemName}}
      <span @click="back" class="back">返回></span>
    </div>
  <div class="form">
    <div class="header">招标信息
      <span class="showOff">
        <el-button @click="open" size="small" type="text">{{isclose?"展开":"收起"}}
          <i :class="[{'el-icon-arrow-down':isclose},{'el-icon-arrow-up':!isclose}]"></i>
        </el-button>
      </span>
    </div>
    <bid-all-info v-if="!isclose" :formData="formData"></bid-all-info>
    <div class="header" v-if="SuccessfulShow">中标单位及评分</div>
    <bid-result v-if="SuccessfulShow" ref="Successful" :formData="formData"></bid-result>
    <div class="header" v-if="quotationShow">投标单位报价表</div>
    <quotation-sheet-bidder v-if="quotationShow" class="quota" :formData="formData"></quotation-sheet-bidder>
    <div class="header" v-if="expertRatingShow">专家评分</div>
    <expert-rating-end v-if="expertRatingShow" ref="expertRatingEnd" :formData="formData"></expert-rating-end>
    <approval-mind></approval-mind>
    <div class="action">
      <el-button v-if="changeShow" @click="change" class="change" type="primary" size="medium">修改</el-button>
      <el-button v-if="changeShow" @click="reject" class="reject" type="primary" size="medium">废标</el-button>
      <el-button v-if="expertShow&&canexpert" @click="repetition" class="repetition" type="primary" size="medium">复标</el-button>
      <el-button v-if="expertShow" @click="submitBid" class="submit" type="primary" size="medium">提交审批</el-button>
      <el-button v-if="rejectShow" @click="flowReject" class="submit" type="primary" size="medium">驳回</el-button>
      <el-button v-if="submitShow" @click="submit" class="submit" type="primary" size="medium">提交</el-button>
    </div>
  </div>
  <dialog-box ref="dialogbox" dialogWidth="40%" :dialogShow="dialogShow" :componentName="dialogBody"
            @handleClose='handleClose' @cancel="handleClose" :title="dialogTitle"
            @changeBid="changeBid" @submit="submitApproval" @RepetBid="RepetBid" :formData="formData" :buttonData="buttonData">
  </dialog-box>
</div>
</template>

<script>
import { dataFormat } from './js/bidConfig'
import Component from '@/assets/js/components.js'
import { request } from '@/assets/js/http.js'
import { FlowCtlData } from '@/assets/js/FlowManage.js'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'BidFormAll',
  data () {
    FlowCtlData.docid = ''
    var id = this.$route.query.id
    var formdata = {}
    FlowCtlData.flowid = 'bid'
    if (id) {
      FlowCtlData.docid = id
      request('/api/bid/getAllSupplierByBidId/' + id, 'get', {}).then((response) => {
        if (response.code === '200') {
          formdata = response.data
          this.formData = response.data
          FlowCtlData.getApprPsn('551625315191681024').then((val) => { this.formData.cgbjlsp = val })
        }
        FlowCtlData.initialize().then(() => {
          this.setBidOffer()
        })
      })
    }
    return {
      formData: formdata,
      dataFormat: dataFormat,
      isclose: false,
      dialogBody: '',
      dialogTitle: '',
      SuccessfulShow: false,
      quotationShow: false,
      expertRatingShow: false,
      submitShow: false,
      changeShow: false,
      rejectShow: false,
      expertShow: false,
      canexpert: false,
      dialogShow: false,
      buttonData: []
    }
  },
  watch: {
    $route: {
      handler () {
        var id = this.$route.query.id
        FlowCtlData.docid = id
        if (id) {
          request('/api/bid/getAllSupplierByBidId/' + id, 'get', {}).then((response) => {
            if (response.code === '200') {
              this.formData = response.data
              FlowCtlData.getApprPsn('551625315191681024').then((val) => { this.formData.cgbjlsp = val })
            }
            FlowCtlData.initialize().then(() => {
              this.setBidOffer()
            })
          })
        }
      }
    }
  },
  methods: {
    setBidOffer: function () {
      if (this.formData.quoteList.length > 0 && (this.formData.status === 1 || this.formData.status === 5 || this.formData.status === 6 || this.formData.status === 7)) {
        this.quotationShow = true
      } else {
        this.quotationShow = false
      }
      if (this.formData.status === 6 || this.formData.status === 7) {
        this.expertRatingShow = true
      } else {
        this.expertRatingShow = false
      }
      if (this.formData.status === 6 &&
       FlowCtlData.nodeThisData[0].APPROVER.split(';').includes(FlowCtlData.loginID) &&
       FlowCtlData.nodeThisData[0].NODEID === 'wHpzSKKR6xAYhKQXdK3a7C8AyRYXspGb') {
        this.expertShow = true
        this.canexpert = true
      }
      if (this.formData.status === 8 &&
       FlowCtlData.nodeThisData[0].APPROVER.split(';').includes(FlowCtlData.loginID) &&
       FlowCtlData.nodeThisData[0].NODEID === 'wHpzSKKR6xAYhKQXdK3a7C8AyRYXspGb') {
        this.expertShow = true
        this.canexpert = false
      }
      if (this.formData.status !== 4 && this.formData.status !== 3 && this.formData.createUserName === localStorage.getItem('userName')) {
        this.changeShow = true
      }
      if (FlowCtlData.nodeThisData[0].APPROVER.split(';').includes(FlowCtlData.loginID) &&
       FlowCtlData.nodeThisData[0].NODEID === 'X4z5NeynSXMYJ3ReJnSDEXjsfmHQJNEG') {
        this.submitShow = true
        this.rejectShow = true
        this.SuccessfulShow = true
      }
      if (this.formData.status !== 0 && FlowCtlData.nodeThisData[0].APPROVER.split(';').includes(FlowCtlData.loginID) &&
       FlowCtlData.nodeThisData[0].NODEID === 'flowStart') {
        this.expertShow = true
        this.SuccessfulShow = true
      }
      if (FlowCtlData.nodeThisData[0].NODEID === 'flowEnd' && this.formData.cgbjlsp.split(';').includes(FlowCtlData.loginID)) {
        this.quotationShow = true
        this.expertRatingShow = true
        this.SuccessfulShow = true
      }
    },
    change: function () {
      this.dialogTitle = '修改'
      this.dialogBody = 'ChangeBid'
      this.buttonData = [{ label: '取消', action: 'cancel', size: 'mini' }, { label: '确认', action: 'changeBid', size: 'mini', type: 'primary' }]
      this.dialogShow = true
    },
    repetition: function () {
      this.dialogTitle = '复标'
      this.dialogBody = 'RepetBid'
      this.buttonData = [{ label: '取消', action: 'cancel', size: 'mini' }, { label: '确认', action: 'RepetBid', size: 'mini', type: 'primary' }]
      this.dialogShow = true
    },
    submitBid: function () {
      this.dialogTitle = '确认中标单位'
      this.dialogBody = 'ConfirmBid'
      this.buttonData = [{ label: '取消', action: 'cancel', size: 'mini' }, { label: '确认', action: 'submit', size: 'mini', type: 'primary' }]
      this.dialogShow = true
    },
    handleClose: function () {
      this.dialogShow = false
    },
    reject: function () {
      this.formData.bhct = 's'
      FlowCtlData.loginID = 'bidAdmin'
      FlowCtlData.getNodeAction('TJ', this.formData).then((val) => {
        if (val) {
          FlowCtlData.alertDialog(this).then(() => {
            FlowCtlData.setFormData(FlowCtlData, this.formData)
            this.formData.approvalComplete = this.formData.approvalComplete + localStorage.getItem('userName') + '(执行废标操作),'
            request('/api/bid/approvalSave', 'post', this.formData).then((response) => {
              if (response.code === '200') {
                request('/api/bid/updateByBidId', 'post', { id: this.formData.id, status: 4 }).then((response) => {
                  if (response.code === '200') {
                    FlowCtlData.setNodeAction().then((val) => {
                      if (val) {
                        FlowCtlData.formAlert(this, 'success')
                        this.dialogShow = false
                        window.history.back()
                      }
                    })
                  }
                })
              }
            })
          }).catch(() => { FlowCtlData.formAlert(this, 'info') })
        }
      }).catch(() => { FlowCtlData.formAlert(this, 'error') })
    },
    submitApproval: function () {
      var list = this.$refs.dialogbox.$refs.content.checkList
      var SyncSap = this.$refs.dialogbox.$refs.content.isSyncSap
      var supplierList = this.$refs.dialogbox.$refs.content.SupplierList
      var bz = this.$refs.dialogbox.$refs.content.bz
      var ConfirmBidList = []
      for (var d of supplierList) {
        for (var s of list) {
          if (s === d.code) {
            var temp = {}
            temp.code = d.code
            temp.approvalBz = d.bz
            temp.isSyncSap = SyncSap
            ConfirmBidList.push(temp)
          }
        }
      }
      this.formData.repetType = 2
      this.formData.bhct = 'y'
      this.formData.status = 7
      FlowCtlData.getNodeAction('TJ', this.formData).then(
        (val) => {
          if (val) {
            this.$confirm('下一环节：' + FlowCtlData.tmpNextMsgInfo.nodeMsg.nodeName + '。是否提交？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              FlowCtlData.setFormData(FlowCtlData, this.formData)
              request('/api/bid/approvalSave', 'post', this.formData).then((response) => {
                if (response.code === '200') {
                  FlowCtlData.setNodeAction().then((val) => {
                    if (val) {
                      request('/api/bid/approval?bidId=' + this.formData.id + '&bz=' + encodeURI(bz), 'post', ConfirmBidList).then((response) => {
                        if (response.code === '200') {
                          this.$message({
                            type: 'success',
                            message: '提交成功!'
                          })
                        }
                      })
                      this.dialogShow = false
                      window.history.back()
                    }
                  })
                }
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消提交'
              })
            })
          }
        }
      )
    },
    submit: function () {
      FlowCtlData.getNodeAction('TJ', this.formData).then(
        (val) => {
          if (val) {
            this.$confirm('下一环节：' + FlowCtlData.tmpNextMsgInfo.nodeMsg.nodeName + '。是否提交？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              FlowCtlData.setFormData(FlowCtlData, this.formData)
              if (FlowCtlData.nextflowactionmsg.nodeMsg.nodeId === 'flowEnd') {
                this.formData.status = 3
              }
              request('/api/bid/approvalSave', 'post', this.formData).then((response) => {
                if (response.code === '200') {
                  FlowCtlData.setNodeAction().then((val) => {
                    if (val) {
                      this.$message({
                        type: 'success',
                        message: '提交成功!'
                      })
                      this.dialogShow = false
                      window.history.back()
                    }
                  })
                }
              }).catch((error) => {
                console.log(error)
                FlowCtlData.formAlert(this, 'error', '未找到审批人！')
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消提交'
              })
            })
          }
        }
      )
    },
    changeBid: function () {
      var formdata = this.$refs.dialogbox.$refs.content.formdata
      formdata.id = this.formData.id
      request('/api/bid/updateByBidId', 'post', formdata).then((response) => {
        if (response.code === '200') {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
        }
      })
      this.dialogShow = false
    },
    flowReject () {
      FlowCtlData.getNodeAction('BH', this.formData).then(
        (val) => {
          if (val) {
            FlowCtlData.alertBHDialog(this).then(({ value }) => {
              FlowCtlData.setFormData(FlowCtlData, this.formData)
              request('/api/bid/approvalSave', 'post', this.formData).then((response) => {
                if (response.code === '200') {
                  FlowCtlData.setNodeAction(value).then((val) => {
                    if (val) {
                      this.$message({
                        message: '驳回成功',
                        type: 'success'
                      })
                      window.history.back()
                    } else {
                      this.$message({
                        message: '操作失败',
                        type: 'error'
                      })
                    }
                  })
                }
              })
            })
          }
        }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消提交'
        })
      })
    },
    RepetBid: function () {
      var bidEndDateStr = this.$refs.dialogbox.$refs.content.bidEndDateStr
      bidEndDateStr = dayjs(bidEndDateStr).format('YYYY-MM-DD HH:mm:ss')
      var list = this.$refs.dialogbox.$refs.content.checkList
      var supplierList = this.$refs.dialogbox.$refs.content.SupplierList
      var RepetBidList = []
      for (var d of supplierList) {
        for (var s of list) {
          if (s === d.code) {
            var temp = {}
            temp.code = d.code
            temp.bz = d.bz
            RepetBidList.push(temp)
          }
        }
      }
      request(`/api/bid/recoveryBid?bidId=${this.formData.id}&bidEndDateStr=${bidEndDateStr}`, 'post', RepetBidList).then((response) => {
        if (response.code === '200') {
          this.$message({
            type: 'success',
            message: '复标成功!'
          })
        }
      })
      this.dialogShow = false
      window.history.back()
    },
    open () {
      this.isclose = !this.isclose
    },
    back: () => {
      window.history.back()
    }
  }
}
</script>
<style scoped lang="scss">
 @import '@/assets/css/form.scss';
 .quota{
   margin-top: 20px;
 }
 .showOff{
   float: right;
 }
 .action{
   margin-top: 40px;
 }
</style>
